import TagManager from 'react-gtm-module';
import { getEnvironmentFromUrl, parseEnv } from 'utils';

const environment = getEnvironmentFromUrl();

const gtmId = parseEnv(`REACT_APP_${environment}_GOOGLE_TAG_MANAGER`);

const config = {
  gtmId,
};

export default () => TagManager.initialize(config);

import { getEnvironmentFromUrl, parseEnv } from 'utils';

const environment = getEnvironmentFromUrl();

// the idea below is that any config can be swapped at runtime using env vars
const baseUrl = parseEnv(`REACT_APP_${environment}_BASE_URL`, `${window.location.origin}`);
const clientId = parseEnv(`REACT_APP_${environment}_CLIENT_ID`);
const tenantId = parseEnv(`REACT_APP_${environment}_TENANT_ID`);
const loginOrigin = parseEnv(
  `REACT_APP_${environment}_LOGIN_ORIGIN`,
  'https://login.microsoftonline.com',
);

const b2cPolicies = {
  names: {
    signIn: 'B2C_1A_SIGNIN',
    signUp: 'B2C_1A_SIGNUP',
    resetPassword: '',
    editProfile: '',
  },
  authorities: {
    signIn: {
      authority: `${loginOrigin}/${tenantId}.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_SIGNIN`,
    },
    signUp: {
      authority: `${loginOrigin}/${tenantId}.onmicrosoft.com/B2C_1A_SIGNUP`,
    },
    resetPassword: {
      authority: `${loginOrigin}/${tenantId}.onmicrosoft.com/`,
    },
    editProfile: {
      authority: `${loginOrigin}/${tenantId}.onmicrosoft.com/`,
    },
  },
};

const b2cConfig = {
  signIn: {
    clientId, // Mandatory field
    authority: b2cPolicies.authorities.signIn.authority,
    redirectUri: `${baseUrl}`,
    scope: 'openid',
    response_type: 'id_token',
    prompt: 'login',
  },
};

function createB2cConfig(config) {
  function createSignInUrl() {
    const {
      clientId: client,
      authority,
      redirectUri,
      scope,
      response_type: responseType,
      prompt,
    } = config.signIn;

    return `${authority}&client_id=${client}&nonce=defaultNonce&redirect_uri=${encodeURIComponent(
      redirectUri,
    )}&scope=${scope}&response_type=${responseType}&prompt=${prompt}`;
  }

  return {
    getSignInUrl: createSignInUrl,
  };
}

const authConfig = createB2cConfig(b2cConfig);

export { authConfig };

import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { parseEnv, getEnvironmentFromUrl } from 'utils';

const environment = getEnvironmentFromUrl();

const appInsightsInstrumentationKey = parseEnv(
  `REACT_APP_${environment}_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY`,
);
const appId = parseEnv(`REACT_APP_${environment}_ID`);

let appInsights;
export default () => {
  if (appInsightsInstrumentationKey == null) {
    return null;
  }

  const reactPlugin = new ReactPlugin();
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: appInsightsInstrumentationKey,
      enableAutoRouteTracking: true,
      extensions: [reactPlugin],
      disableFetchTracking: false,
      autoTrackPageVisitTime: true,
      appId,
    },
  });

  appInsights.loadAppInsights();
  return {
    appInsights,
    reactPlugin,
  };
};

export const updateAppInsightsUser = (userId) => {
  if (appInsights == null) {
    return;
  }
  if (userId != null) {
    appInsights.setAuthenticatedUserContext(userId);
  } else {
    appInsights.clearAuthenticatedUserContext();
  }
};

export const trackCustomEvent = (name, properties) => {
  if (appInsights == null) {
    return;
  }
  appInsights.trackEvent({ name, properties });
};

export const trackException = (error, info) => {
  if (appInsights == null) {
    return;
  }

  appInsights.trackException({
    error,
    exception: error,
    severityLevel: SeverityLevel.error,
    properties: { ...info },
  });
};

export const updateDataLayer = (event, value) => {
  window.dataLayer.push({
    event,
    value,
  });
};
